<template>
  <v-container>
    <v-row>
      <v-col cols="3" lg="3" xl="3" v-if="$vuetify.breakpoint.lgAndUp">
        <app-drawer-business
          :notFoundBuisness="notFoundBuisness"
        ></app-drawer-business>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="text-center mt-8">
        En esta sección podras visualizar tus ordenes realizadas en este negocio
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DrawerBusiness from "../components/marketplace/DrawerBusiness";

export default {
  methods: {
    ...mapActions("app", ["initBuinessDatabase"]),
    async getBuinessDatabase(businessName) {
      this.loadingFirstData = true;
      const resultBusiness = await this.initBuinessDatabase(businessName);
      switch (resultBusiness) {
        case "NOT_FOUND":
          this.loadingFirstData = false;
          this.notFoundBuisness = true;
          break;
        case "OK":
          this.getProducts();
          break;
        default:
          this.loadingFirstData = false;
          break;
      }
    }
  },
  components: {
    AppDrawerBusiness: DrawerBusiness
  },
  props: {
    businessName: {
      required: true,
      type: String
    }
  }
};
</script>

<style></style>
